<template>
  <div class="education">
    <div class="education__radio">
      <TabsRadio
        :tabData="tabsRadioIsStudent"
        v-model="is_not_student"
        :error="v$.is_not_student.$errors.length !== 0"
        @update:modelValue="
          () => {
            this.formData = {};
            this.educational_establishment_name = '';
            this.education_city_name = '';
            this.education_region_name = '';
            this.faculty_name = '';
            this.vuz_type = '';
          }
        "
      />
    </div>
    <div class="education__radio">
      <TabsRadio
        :tabData="is_not_student ? tabsRadioPastEduction : tabsRadioEducation"
        v-model="vuz_type"
        :error="v$.vuz_type.$errors.length !== 0"
        @update:modelValue="
          () => {
            Object.keys(this.formData).forEach(
              (item) => (this.formData[item] = '')
            );
            this.faculty_name = '';
            this.education_city_name = '';
            this.education_region_name = '';
            this.educational_establishment_name = '';
            if (this.vuz_type === 2) {
              this.formData.level_education = 1;
            }
            if (user.info_educational_establishment?.type?.key === vuz_type) {
              loadInfo();
            }
          }
        "
      />
    </div>
    <div class="education__input" v-if="vuz_type">
      <InputSearch
        header="Регион обучения*"
        placeholder="Выберите из списка"
        :results-array="regionsList"
        @form-item="setIdRegion"
        v-model="education_region_name"
        :error="v$.formData.education_region_id.$errors.length !== 0"
      />
      <InputSearch
        header="Город обучения*"
        placeholder="Выберите из списка"
        :results-array="filteredCitiesList"
        @form-item="setIdCity"
        v-model="education_city_name"
        :error="v$.formData.education_city_id.$errors.length !== 0"
        :disabled="!formData.education_region_id"
      />
      <InputSearch
        header="Учебное заведение*"
        placeholder="Начните вводить аббревиатуру или наименование"
        :results-array="filteredUniversitiesList"
        @form-item="setIdUniversity"
        v-model="educational_establishment_name"
        :error="v$.formData.educational_establishment_id.$errors.length !== 0"
        :disabled="!formData.education_city_id"
        @update:modelValue="
          () => {
            faculty_name = '';
            formData.faculty_id = '';
            if (!educational_establishment_name) {
              formData.educational_establishment_id = '';
            }
          }
        "
      />
      <RadioBtnToggle
        v-model="noVuz"
        :label="'Не нашёл своё образовательное учреждение'"
      />
      <InputSearch
        v-if="facultiesList?.length > 1 || facultiesList?.length === 0"
        header="Факультет/институт*"
        placeholder="Выберите из списка"
        :results-array="facultiesList"
        @form-item="setIdFaculty"
        v-model="faculty_name"
        :error="v$.formData.faculty_id.$errors.length !== 0"
        :key="universitiesList"
        :disabled="
          facultiesList?.length === 0 || !formData.educational_establishment_id
        "
        :readonly="
          facultiesList?.length === 0 || !formData.educational_establishment_id
        "
      />
      <Input
        header="Специальность*"
        placeholder="Введите свою специальность"
        v-model="formData.speciality_education"
        :error="v$.formData.speciality_education.$errors.length !== 0"
      />
      <Dropdown
        header="Уровень образования*"
        placeholder="Выберите из списка"
        v-model="formData.level_education"
        :keys="
          educationLevelList.map((item) => {
            return item.translate;
          })
        "
        :items="
          educationLevelList.map((item) => {
            return item.id;
          })
        "
        :error="v$.formData.level_education.$errors.length !== 0"
        last
        @update:modelValue="
          () => {
            this.levelEducationChange();
          }
        "
      />
      <Dropdown
        header="Курс*"
        placeholder="Выберите из списка"
        :items="currentCoursesList"
        :keys="currentCoursesList"
        v-model="formData.course_level"
        :disabled="!formData.level_education"
        :error="v$.formData.course_level.$errors.length !== 0"
        last
      />
      <Input
        header="Группа*"
        placeholder="Введите данные"
        v-model="formData.group"
        :error="v$.formData.group.$errors.length !== 0"
        v-if="
          this.formData.level_education !== 15 &&
          this.formData.level_education !== 18
        "
      />
      <Dropdown
        header="Год окончания учебного заведения*"
        :items="is_not_student ? yearEndingNotStudent : yearEnding"
        :keys="is_not_student ? yearEndingNotStudent : yearEnding"
        placeholder="Выберите из списка"
        v-model="formData.year_ending_education"
        :error="v$.formData.year_ending_education.$errors.length !== 0"
        last
      />
      <DocsUpload
        label="Студенческий билет (по желанию)"
        currentDocsOrientation="horizontal"
        keyName="document_student_billet"
        :file-loaded="
          user.education?.document_files?.document_student_billet?.full ||
          !!docs['document_student_billet']
        "
        @update:modelValue="
          (value) => {
            this.document_student_billet = value;
          }
        "
      />
    </div>
    <div class="education__btn" v-if="vuz_type">
      <Button @click="saveInfo" :loading="isBtnLoading">
        Сохранить изменения
      </Button>
    </div>
  </div>
</template>

<script>
import tabsRadioEducation from "@/enums/tabsRadioEducation";
import tabsRadioPastEduction from "@/enums/tabsRadioPastEduction";
import Input from "@/components/Blocks/Input";
import Dropdown from "@/components/Blocks/Dropdown";
import Button from "@/components/Blocks/Button";
import TabsRadio from "@/components/Blocks/TabsRadio";
import { mapActions, mapGetters, mapState } from "vuex";
import InputSearch from "@/components/Blocks/InputSearch";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import validations from "@/enums/validations";
import trayValidationsTexts from "@/enums/trayValidationsTexts";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import DocsUpload from "@/components/Blocks/DocsUpload";
import { helpers, required } from "@vuelidate/validators";
import { setDisabledField } from "@/utils/helpers";
import tabsRadioIsStudent from "@/enums/tabsRadioIsStudent";
import uniqueString from "unique-string";
import Toggle from "@/components/Blocks/Toggle.vue";
import Radio from "@/components/Blocks/Radio.vue";
import RadioBtnToggle from "@/components/Blocks/RadioBtnToggle.vue";

export default {
  name: "Education",
  components: {
    RadioBtnToggle,
    Radio,
    Toggle,
    DocsUpload,
    InputSearch,
    TabsRadio,
    Button,
    Dropdown,
    Input,
  },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      uniqueId: uniqueString(),
      isBtnLoading: false,
      tabsRadioEducation,
      tabsRadioPastEduction,
      tabsRadioIsStudent,
      educational_establishment_name: "",
      faculty_name: "",
      vuz_type: "",
      noVuz: false,
      is_not_student: "",
      document_student_billet: "",
      formData: {
        educational_establishment_id: "",
        faculty_id: "",
        speciality_education: "",
        group: "",
        year_ending_education: "",
        level_education: "",
        course_level: "",
        education_city_id: "",
        education_region_id: "",
      },
      education_city_name: "",
      education_region_name: "",
    };
  },
  watch: {
    user() {
      this._init();
    },
    docs: {
      handler: function () {
        return (this.document_student_billet =
          this.docs["document_student_billet"]);
      },
      deep: true,
    },
  },
  validations() {
    if (
      this.formData.level_education === 15 ||
      this.formData.level_education === 18
    ) {
      validations.educationData.group = {};
    } else
      validations.educationData.group.required = helpers.withMessage(
        "Обязательно к заполнению",
        required
      );
    return {
      formData: validations.educationData,
      vuz_type: validations.vuz_type,
      is_not_student: validations.is_not_student,
    };
  },
  computed: {
    fieldsInfo() {
      return trayValidationsTexts.educationData;
    },
    universitiesList() {
      let array = JSON.parse(
        JSON.stringify(this.storage.systemInfo.educational_establishments)
      );
      return array.filter((item) => {
        return this.vuz_type === item.type?.key;
      });
    },
    facultiesList() {
      let array = JSON.parse(JSON.stringify(this.universitiesList));
      let item = array.find((item) => {
        return item.id === this.formData.educational_establishment_id;
      });
      return item?.faculties;
    },
    educationLevelList() {
      let sortedArr = JSON.parse(
        JSON.stringify(this.storage.systemInfo.level_education)
      );

      Object.values(sortedArr).forEach((item, index) => {
        item.id = +Object.keys(sortedArr)[index];

        return item;
      });
      if (this.vuz_type === 2) {
        return Object.values(sortedArr).filter((item) => {
          return item.id === 1;
        });
      } else {
        return Object.values(sortedArr).filter((i) => {
          return i.id !== 1;
        });
      }
    },
    currentCoursesList() {
      return this.educationLevelList.find((item) => {
        return item.id === this.formData.level_education;
      })?.settings?.course_list;
    },
    yearEnding() {
      return Array.from(Array(7).keys()).map((item) => {
        return (item += moment().year());
      });
    },
    yearEndingNotStudent() {
      return Array.from(Array(16).keys())
        .map((item) => {
          return (item = moment().subtract(item, "year").year());
        })
        .sort((a, b) => {
          return a - b;
        });
    },
    filteredUniversitiesList() {
      return this.universitiesList
        .filter((university) => {
          return university?.city_id === this.formData.education_city_id;
        })
        .sort((a, b) => {
          let fieldA = a?.title_full?.toLowerCase();
          let fieldB = b?.title_full?.toLowerCase();

          if (fieldA < fieldB) return -1;

          if (fieldA > fieldB) return 1;

          return 0;
        });
    },
    filteredCitiesList() {
      return this.citiesList.filter((city) => {
        return city?.region_id === this.formData.education_region_id;
      });
    },

    ...mapState(["storage", "docs"]),
    ...mapState("user", ["user", "disabledFields", "isEducationPopupShown"]),
    ...mapGetters(["regionsList", "citiesList"]),
  },
  methods: {
    async setFakeUserData() {
      Object.keys(this.formData).forEach((item) => {
        this.user[item] = this.formData[item];
      });
      if (!this.user.info_educational_establishment?.id)
        this.user.info_educational_establishment = {
          id: null,
          title_full: "",
          type: { vuz_type: "" },
        };

      if (!this.user.info_educational_establishment?.id)
        this.user.info_educational_establishment = {
          id: null,
          title_full: "",
          type: { vuz_type: "" },
        };

      this.user.info_educational_establishment.id =
        this.formData.educational_establishment_id;
      this.user.info_educational_establishment.title_full =
        this.educational_establishment_name;
      this.user.info_educational_establishment.type.key = this.vuz_type;

      this.user.info_educational_establishment?.type?.key === 2
        ? this.$store.commit("user/setIsSPO", true)
        : this.$store.commit("user/setIsSPO", false);

      this.user.info_faculty = {
        id: this.formData.faculty_id,
        title_full: this.faculty_name,
      };
      this.user.info_level_education.id = this.formData.level_education;
      this.user.info_course_level.id = this.formData.course_level;
      this.user.is_not_student = this.is_not_student;
      this.$store.commit(
        "user/setIsRT",
        this.formData.education_region_id === 5246
      );
    },
    setIdRegion(item) {
      this.formData.education_region_id = item?.region_id;
      this.formData.education_city_id = "";
      this.education_city_name = "";
      this.educational_establishment_name = "";
      this.formData.educational_establishment_id = "";
    },
    setIdCity(item) {
      this.formData.education_city_id = item.id;
      this.formData.educational_establishment_id = "";
      this.educational_establishment_name = "";
    },
    setIdUniversity(item) {
      this.formData.educational_establishment_id = item.id;
      this.formData.faculty_id = "";
      this.faculty_name = "";
      if (this.facultiesList && this.facultiesList.length === 1) {
        this.formData.faculty_id = this.facultiesList[0].id;
        this.faculty_name = this.facultiesList[0].title_full;
      }
      if (this.facultiesList && this.facultiesList.length === 0) {
        this.formData.faculty_id = "";
        this.faculty_name = "";
      }
    },
    setIdFaculty(item) {
      this.formData.faculty_id = item.id;
    },
    async saveInfo() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }

      let payloadObject = {};
      Object.keys(this.formData)
        .filter((i) => {
          return !i.startsWith("document");
        })
        .forEach((item) => {
          if (this.formData[item]) {
            return (payloadObject[item] = this.formData[item]);
          }
        });
      payloadObject.is_not_student = this.is_not_student ? "1" : "0";
      this.isBtnLoading = true;

      createRequest(requestConfigs.POSTSaveInfo, {
        jsonPayload: payloadObject,
      })
        .then(() => {
          this.$store.commit("pushToTray", {
            text: "Данные успешно сохранены",
            type: "success",
          });
          this.setFakeUserData().then(() => {
            if (!this.disabledFields["isEducationFilled"]) {
              this.$store.commit("setCurrentPopup", {
                name: "PopupProfileCongrats",
                isShown: true,
                props: {},
              });
            } else {
              this.$store.commit("user/setDisabledData", [
                "isNotStudent",
                this.is_not_student,
              ]);
              this.setDisabledField(
                [
                  "educational_establishment_id",
                  "faculty_id",
                  "level_education",
                ],
                "isEducationFilled",
                this.formData
              ).then(() => {
                this.$router.push({ name: "PersonalData" });
              });
            }
          });
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isBtnLoading = false;
        });

      if (!this.document_student_billet?.full && this.document_student_billet) {
        createRequest(requestConfigs.POSTOneDocument, {
          jsonPayload: {
            key_document: "document_student_billet",
            file_document: this.document_student_billet,
          },
        })
          .then(() => {
            this.$store.commit("pushToTray", {
              text: "Скан студенческого билета загружен",
              type: "success",
            });
            if (this.user.info_validation_documents.data.length > 0) {
              let infoDocument = this.user.info_validation_documents.data.find(
                (item) => {
                  return item.key_document === "document_student_billet";
                }
              );
              if (infoDocument) {
                infoDocument.status = 0;
              }
            }
          })
          .catch((error) => {
            this.$store.commit("pushToTray", {
              text: error.errors[0].error_descr,
              type: "error",
            });
          });
      }
    },
    async _init() {
      await this.loadInfo();
      this.vuz_type = this.user.info_educational_establishment?.type?.key;
      this.is_not_student = this.user.is_not_student;
    },
    async loadInfo() {
      Object.keys(this.formData).forEach((item) => {
        return (this.formData[item] = this.user[item]);
      });

      this.educational_establishment_name =
        this.user.info_educational_establishment?.title_full;
      this.formData.educational_establishment_id =
        this.user.info_educational_establishment?.id;
      this.faculty_name = this.user.info_faculty?.title_full;
      this.formData.faculty_id = this.user.info_faculty?.id;
      this.formData.course_level = this.user.info_course_level?.id;
      this.formData.level_education = this.user.info_level_education?.id;
      this.formData.education_region_id = this.user.education_region_id;
      this.formData.education_city_id = this.user.education_city_id;
      this.education_city_name = await this.getPlaceName({
        id: this.user.education_city_id,
        type: "city",
      });
      this.education_region_name = await this.getPlaceName({
        id: this.user.education_region_id,
        type: "region",
      });
      if (this.user.education) {
        if (
          this.user.education.document_files.document_student_billet &&
          this.user.education.document_files.document_student_billet.full
        ) {
          return (this.document_student_billet =
            this.user.education.document_files.document_student_billet);
        }
      }
    },
    levelEducationChange() {
      this.formData.course_level = "";
      if (
        this.formData.level_education === 15 ||
        this.formData.level_education === 18
      ) {
        this.formData.group = "";
      }
    },
    setDisabledField,
    ...mapActions(["getPlaceName"]),
  },
  mounted() {
    if (this.user) {
      this._init();
    }
  },
};
</script>

<style lang="scss">
.education {
  &__radio {
    display: flex;
    gap: 12px;
    margin-bottom: 23px;
  }
  &__input {
    max-width: 728px;
  }
  &__btn {
    margin-top: 10px;
  }
}
</style>
